<template>
  <div class="form-group">
    <label for="tags">
      <span>{{ $t("tags") }}</span>
      <Tooltip :title="$t('hints.tags')" />
    </label>
    <vue-tags-input
      name="tags"
      class="tags-input form-control"
      v-model="tag"
      :placeholder="$t('placeholders.tags')"
      :tags="tags"
      :allow-edit-tags="true"
      :add-from-paste="false"
      :add-on-key="[13, ':', ';']"
      :validation="tag_validation"
      @tags-changed="(newTags) => (tags = newTags)"
      ref="inp"
    />
  </div>
</template>

<script>
import { isEqual } from "lodash";
import VueTagsInput from "@johmun/vue-tags-input";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "TagsForm",
  components: {
    VueTagsInput,
    Tooltip
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      tag: "",
      tags: []
    };
  },
  computed: {
    tag_validation() {
      if (!this.tags || !this.tags.length) {
        return [];
      }
      var lst = [
        {
          classes: "min-length",
          rule: (tag) => tag.text.length < 3
        },
        {
          classes: "avoid-only-numbers",
          rule: /[^0-9]/,
          disableAdd: true
        },
        {
          classes: "no-braces",
          rule: ({ text }) => /[\#\{\}]/.test(text),
          disableAdd: true
        }
      ];
      return lst;
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.tags)) return;
          this.$set(this, "tags", JSON.parse(JSON.stringify(n)));
        }
      },
      immediate: true,
      deep: true
    },
    tags: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.value)) return;
          this.$emit("input", JSON.parse(JSON.stringify(n)));
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.tags-input {
  padding: 0;
  resize: vertical;
  height: auto;
  margin-bottom: -2px;
}
</style>
