<template>
  <i
    v-if="ready && icon"
    class="tooltip-icon"
    :class="icon"
    data-toggle="tooltip"
    :data-placement="position"
    data-html="true"
    ref="tooltip"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    @click.stop.prevent="click"
  />
  <span
    v-else-if="ready"
    data-toggle="tooltip"
    data-html="true"
    ref="tooltip"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    @click.stop.prevent="click"
    :data-placement="position"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    title: {
      type: String,
      default: "TODO: add the title property to your component",
      required: false
    },
    icon: {
      type: String,
      default: "fa fa-question-circle",
      required: false
    },
    position: {
      type: String,
      default: "auto",
      required: false
    }
  },
  data() {
    return {
      ready: true
    };
  },
  watch: {
    title(n) {
      if (n && this.$widget) {
        this.$widget
          .attr("data-original-title", n)
          .tooltip("fixTitle")
          .tooltip("setContent");
      }
    }
  },
  methods: {
    click() {
      if (this.$widget) {
        this.$emit("click");
        this.$widget.tooltip("hide");
        this.$nextTick(() => {
          this.$widget.tooltip("show");
        });
      }
    }
  },
  mounted() {
    this.$widget = $(this.$refs.tooltip).tooltip({
      html: true,
      sanitize: false,
      animation: false,
      title: () => {
        return this.title;
      }
    });
  },
  beforeDestroy() {
    if (this.$widget) {
      this.$widget.tooltip("destroy");
    }
  }
};
</script>
<style scoped>
[data-toggle="tooltip"] {
  margin-left: 0.2rem;
}
</style>
<style>
.tooltip-icon {
  z-index: 900;
  opacity: 1;
}

.tooltip-inner {
  max-width: 250px;
  z-index: 9999 !important;
  opacity: 1 !important;
}

.tooltip {
  position: fixed;
  z-index: 9999 !important;
  opacity: 1 !important;
}
.tooltip-inner table td {
  border: 1px solid rgb(92, 92, 92);
  padding: 0 2px;
}
</style>
