const warnUnsavedChanged = (vm, msg, cb) => {
  let wrapper = document.createElement("div");
  
  if (typeof msg == "string") {
    wrapper.innerHTML =
    vm.$t(msg || "this_will_reset_all_of_your_unpushed_changes") +
    "<br>" +
    vm.$t("you_wont_be_able_to_revert_this");
  } else {
    wrapper.innerHTML = vm.$t(msg.msg || "this_will_reset_all_of_your_unpushed_changes");
    
    if (msg.show_revert_message) {
      wrapper.innerHTML += "<br>" +
      vm.$t("you_wont_be_able_to_revert_this");
    }
  }
  
  vm.$swal({
    title: vm.$t("are_you_sure"),
    content: wrapper,
    icon: "warning",
    buttons: [vm.$t("cancel"), vm.$t("confirm")]
  }).then((option) => {
    if (option) {
      if (cb && typeof cb == "function") cb(option);
    }
  });
};
export { warnUnsavedChanged };

export default {
  data: function() {
    return {
      alert: null,
      defaultConfig: {
        success: {
          type: "toast",
          options: {
            // ============================
            // 'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left'
            //  with: -1 force singleton mode
            position: "bottom-right",

            // ============================
            duration: 5000,
            // duration = 0 - force dismissible

            // ============================
            icon: "fa-check"
          }
        },
        error: {
          type: "modal",
          options: {
            icon: "error",
            duration: 0
          }
        }
      }
    };
  },
  methods: {
    outOfQuota(quota, used) {
      return used >= quota;
    },
    async validatePrivileges(action, subject) {
      let self = this;
      return new Promise((resolve) => {
        if (self.$can(action, subject)) {
          resolve(true);
        } else {
          self
            .$swal({
              title: self.$t("not_allowed"),
              text: self.$t(
                "you_do_not_have_enough_privileges_to_execute_this_action"
              ),
              icon: "error",
              type: "error"
            })
            .then((result) => {
              self.alert = null;
              resolve(false);
            });
        }
      });
    },
    /**
     * Validates the resource quota
     *
     * @param {string} resource_name name of the resoure (eg. Connectors)
     * @param {string} quota max number of resources
     * @param {string} count already used resources
     * @returns {string} status [proceed|upgrade|cancel]
      
      "proceed" - proceed on the operation
      "upgrade" - upgrade before can proceed on the operation
      "cancel"  - cancel the operation 

     */
    async validateResourceQuota(resource_name, quota, used) {
      let self = this;
      return new Promise((resolve) => {
        if (self.outOfQuota(quota, used)) {
          self
            .$swal({
              title: self.$t("limit_exceeded"),
              content: self.warningContent(
                "resource",
                self.$t(resource_name),
                "you_have_reached_your_plan_limit"
              ),
              icon: "success",
              type: "success",
              buttons: [self.$t("cancel"), self.$t("Upgrade")]
            })
            .then(function(upgrade) {
              resolve(upgrade ? "upgrade" : "cancel");
            });
        } else {
          resolve("proceed");
        }
      });
    },
    showAlert: function(onClose) {
      var self = this;
      var _cb = onClose || function() {};
      if (self.alert) {
        let options = null;
        let cfg = self.defaultConfig;
        if (
          self.$root.config.alert &&
          self.alert.type in self.$root.config.alert
        ) {
          Object.assign(cfg, self.$root.config.alert);
        }
        let position = (cfg[self.alert.type].options.position || "")
          .replace(/(1\-|\-1)/g, "")
          .toLowerCase();
        let duration = cfg[self.alert.type].options.duration;
        if (cfg[self.alert.type].type == "toast") {
          let singleton =
            cfg[self.alert.type].options.position.indexOf("1") >= 0;
          options = {
            type: self.alert.type,
            icon: self.alert.type == "success" ? "fa-check" : "info",
            iconPack: "fontawesome"
          };
          if (position) {
            options.position = position;
          }
          if (singleton) {
            options.singleton = true;
          }
          if (duration) {
            options.duration = duration;
          } else {
            options.action = {
              icon: "fa-close",
              onClick: (e, me) => {
                me.goAway(0);
              }
            };
          }
          this.$toasted.show(self.alert.html || self.alert.text, options);
          self.alert = null;
          _cb();
        } else {
          let icon = cfg[self.alert.type].options.icon || "";
          options = {
            title: self.alert.title || "Untitled"
          };
          if (position) {
            options.position = {
              "top-left": "top-start",
              "top-right": "top-end",
              "top-center": "top",
              "bottom-left": "bottom-start",
              "bottom-right": "bottom-end",
              "bottom-center": "bottom",
              "center-left": "center-start",
              "center-right": "center-end",
              center: "center"
            }[position];
          }
          if (duration) {
            options.timer = duration;
          }
          if (icon) {
            options.icon = icon;
          } else {
            options.icon =
              self.alert.type == "success" || self.alert.type == "error"
                ? self.alert.type
                : "info";
          }
          if (self.alert.html) {
            options.content = self.wrap(self.alert.html || "Untitled");
          } else {
            options.text = self.alert.text || "Untitled";
          }
          self.$swal(options).then((result) => {
            self.alert = null;
            _cb();
          });
        }
      } else {
        _cb();
      }
    },
    validateSaveResponse: function(ret, msg) {
      let self = this;
      if (ret && typeof ret == "object" && !(ret instanceof Array)) {
        let txt = msg || self.$t("you_have_saved_n_items", { count: 1 });
        let html = "";
        if ("name" in ret && ret.name) {
          html = txt + "<br>" + ret.name;
        }
        self.alert = {
          title: self.$t("save"),
          text: txt,
          html: html,
          type: "success"
        };
        return true;
      } else {
        let text;
        if (ret instanceof Array)
          // Multiple return is not an error always
          if (ret.length > 0 && typeof ret[0] == "object" && "id" in ret[0]) {
            let txt = msg || self.$t("you_have_saved_n_items", { count: ret.length });
            let html = "";
            self.alert = {
              title: self.$t("save"),
              text: txt,
              html: html,
              type: "success"
            };
            return true;
          } else {
            text = ret
              .map(
                (entry) => `${entry[1]}`
              )
              .join("\n");
          }
        else if (typeof ret == "string") {
          text = ret;
        }

        self.alert = {
          title: self.$t("item_not_saved"),
          text: text || self.$t("item_could_not_be_saved"),
          type: "error"
        };
        return false;
      }
    },
    validateDeleteResponse: function(ret) {
      let self = this;
      if (ret && typeof ret == "object") {
        self.alert = {
          title: self.$t("delete"),
          text: self.$t("you_have_deleted_n_items", { count: 1 }),

          type: "success"
        };
        return true;
      } else {
        self.alert = {
          title: self.$t("invalid_action"),
          text:
            (ret && typeof ret == "string" && ret) ||
            self.$t("item_could_not_be_deleted"),

          type: "error"
        };
        return false;
      }
    },
    warningContent(label, value, message, icon) {
      let self = this;
      let field_name =
        label instanceof Array ? self.$tc(label[0], label[1]) : self.$tc(label);
      let warning = "";
      if (message) {
        let text = self.$tc(message);
        let cls = icon || "fa fa-exclamation-triangle";
        warning = `<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div>`;
      }
      let html = `<b>${field_name}</b>: ${value}${warning}`;
      return this.wrap(html);
    },
    wrap(html) {
      let wrapper = document.createElement("div");
      wrapper.innerHTML = html;
      return wrapper;
    },
    warnUnsavedChanged(msg, callback) {
      warnUnsavedChanged(this, msg, callback);
    }
  }
};
