export const pt = {
  serial_number: "Número de série",
  name: "Nome",
  memory_type_id: "Tipo de memória",
  memory_address: "Endereço de memória",
  limit: "Limite",
  non_field_errors: "Erro sem relação a campo",
  location_name: "Localização",
  enabled: "Habilitado",
  protocol_id: 'Protocolo',
  old_password: "Senha anterior"
};
export const en = {
  serial_number: "Serial number",
  name: "Name",
  memory_type_id: "Memory type",
  memory_address: "Memory address",
  limit: "Limit",
  non_field_errors: "Non-field error",
  location_name: "Location",
  enabled: "Enabled",
  protocol_id: 'Protocol',
  old_password: "Old password"
};
